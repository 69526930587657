
body {
  background: transparent; /* Make it white if you need */
  padding: 0 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  color: #72a24d;
}

.message {
  display: flex;
  margin-bottom: 16px;
  max-width: 80%;
  word-wrap: break-word;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatText {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 8px 12px;
  line-height: 1.4;

  white-space: pre-wrap;
}

.right .chatText {
  background-color: #0084ff;
  color: white;
}

.username {
  font-weight: bold;
  margin-bottom: 4px;
}

.content {
  font-size: 16px;
  margin-bottom: 4px;
}

.timestamp {
  font-size: 12px;
  color: #999999;
}